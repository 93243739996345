import { EventContext } from './context/EventContext'
import React, { useContext } from 'react'
import { Dropdown } from '@monbanquet/crumble'
import { useRouteLoading } from '../hooks/useRouteLoading'
import { filter } from 'lodash'

const CategoriesDropdown = ({ onChangeCategory = () => {}, ...props }) => {
  const {
    state: { category },
    categories,
    dispatch,
  } = useContext(EventContext)

  const { loading, shouldLoad } = useRouteLoading(
    'routeUpdateDelayed',
    'routeUpdate',
    { initialShouldLoad: false },
  )

  const filteredCategories = filter(categories, category => {
    return !category.hideMenus
  })

  return (
    <Dropdown
      items={filteredCategories}
      value={category}
      loading={loading}
      onChange={cat => {
        shouldLoad.current = true
        dispatch({
          type: 'UPDATE_CATEGORY',
          payload: { category: cat },
          analytics: { label: 'From_CategoriesDropdown' },
        })
        onChangeCategory(cat)
      }}
      {...props}
    />
  )
}

export default CategoriesDropdown
